@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply h-full;
}
#root {
  @apply flex flex-col;
}
body {
  @apply bg-slate-200 dark:bg-slate-800 dark:text-slate-400;
  /* font-family: "HelveticaNeue-Light", "Helvetica Neue Light", Helvetica, Arial, "Lucida Grande", sans-serif; */
}
